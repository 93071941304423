import {useEffect, useRef} from "react";
import * as stream from "stream";

const Audio: React.FC<{track: MediaStreamTrack}> = ({track}) =>{
    const  audioRef = useRef<HTMLAudioElement>(null)

    useEffect(()=>{
            if(audioRef.current) {
                audioRef.current.srcObject = new MediaStream();
                audioRef.current.srcObject.addTrack(track)
            }
        }
        ,[track])


    return(
        <audio ref={audioRef} autoPlay/>
    )
}
export default Audio